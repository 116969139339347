import React from "react"
import Container from "../components/container"
import FinalCTA from "../components/final-cta"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Contact = () => {
  return (
    <Layout>
      <Container>
        <div className="text-center pt-24 pb-24" style={{ zIndex: 30 }}>
          <h1 className=" mb-4 text-6xl mt-0 tracking-normal">Contact Us</h1>
          <h2 className="mb-12 normal-case text-3xl tracking-normal font-normal text-lightBlack">
            We would love to hear from you
          </h2>
          <div className="flex flex-col">
            <p className="mb-4 text-xl font-bold">
              Call/text: <br />
              <a className="text-lightBlack mb-2" href="tel:+12086448301">
                (208) 644-8301
              </a>
            </p>
            <p className="mb-4 text-xl font-bold">
              Email:
              <br />
              <a
                className="text-lightBlack mb-6"
                href="mailto: taylor@scottsschoolfordogs.com"
              >
                taylor@scottsschoolfordogs.com
              </a>
            </p>
          </div>
        </div>
        <form
          className="bg-white mb-32 rounded px-8 pt-6 pb-8 max-w-md mx-auto shadow-lg"
          name="Contact"
          method="POST"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="Contact" />
          <h3 className="text-center mt-2 mb-4">Contact Form</h3>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              name="name"
              placeholder="First and Last Name"
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Email"
            >
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              name="email"
              placeholder="Email"
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              className="form-textarea shadow border rounded w-full h-[150px] py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="message"
              name="message"
              cols={3}
              placeholder="Your Message..."
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className={`bg-primary hover:bg-lightPrimary rounded-md py-2 font-semibold px-6 text-white `}
              type="submit"
            >
              Submit{" "}
            </button>
          </div>
        </form>
      </Container>
      <FinalCTA />
    </Layout>
  )
}

export const Head = () => <Seo title={"Contact"} />

export default Contact
